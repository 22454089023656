<template>
  <div>
    <Breadcrumb :nameNav="nameNav" :itemFilter="itemFilter" />
    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <b-card-header class="pb-50">
        <h5 class="text-capitalize">
          <span v-if="!isUpdating">{{ $t("actions.add") }}</span>
          <span v-else>{{ $t("actions.update") }}</span>
          {{ $t("action_plan.title") }}
        </h5>
      </b-card-header>
      <b-card-body>
        <div class="mx-2 mb-2">
          <validation-observer ref="refFormObserver">
            <div class="m-2">
              <!-- ===================================== -->
              <b-row class="mb-2">
                <b-col md="12" class="mb-2">
                  <h3>{{ $t("action_plan.title") }}</h3>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Command name"
                    rules="required"
                  >
                    <label class="d-block">{{
                      $t("action_plan.fields.name.label")
                    }}</label>
                    <b-form-input
                      v-model="actionPlanName"
                      class="d-inline-block mr-1"
                      :placeholder="$t('action_plan.fields.name.placeholder')"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    name="Tenants"
                    rules="required"
                  >
                    <b-form-group :label="$tc('tenants.title')" label-for="id_tenant">
                      <v-select
                        v-model="idTenant"
                        :options="tenantOptions"
                        :clearable="false"
                        :reduce="(val) => val.value"
                        input-id="id_tenant"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <validation-provider
                    #default="validationContext"
                    :name="$t('alarm_views.fields.alarm_type.label')"
                    rules="required"
                  >
                    <label class="d-block" for="alarm_type">
                      {{ $t("alarm_views.fields.alarm_type.label") }}
                    </label>
                    <v-select
                      v-model="selectedAlarmType"
                      :options="alarmTypeOptions"
                      class="w-100"
                      input-id="alarm_type"
                      multiple
                      :reduce="(val) => val.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col cols="6" class="mb-1 mb-md-0">
                  <b-form-checkbox
                    id="alarm_compatible"
                    v-model="defaultActionPlan"
                    value="1"
                    unchecked-value="0"
                    name="alarm_compatible"
                    class="my-1 text-capitalize"
                    switch
                  >
                    {{ $t("action_plan.fields.default.label") }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <hr />
              <!-- ===================================== -->
              <b-row>
                <b-col md="12" class="d-flex flex-column">
                  <div class="text-danger" v-if="flowchartError">
                    <span v-if="flowchartError.reason === 'connections_empty'">
                      - {{ $t(`commands.messages.${flowchartError.reason}`) }}
                    </span>
                  </div>
                  <div class="mt-1" v-if="missingNodeData.length">
                    <span class="text-danger">
                      - {{ $t('commands.messages.incomplete_node_config') }}:
                    </span>
                    <ul class="list-unstyled pl-1">
                      <li v-for="(error, index) in missingNodeData" :key="index">
                        {{ $t(`commands.list.${error.node.data.label}.name`) }}
                      </li>
                    </ul>
                  </div>
                </b-col>
                <b-col md="12" class="mb-1">
                  <div class="d-flex justify-content-end px-1">
                    <button
                      class="btn btn-primary"
                      v-if="isUpdating"
                      @click="showActionPlanPreviewModal()"
                    >
                      {{ $t("buttons.preview") }}
                    </button>
                  </div>
                </b-col>
                <b-col md="12">
                  <flowchart-component
                    v-if="commandOptions.length"
                    :command-options="commandOptions"
                    :missing-node-data="missingNodeData"
                    @flowchart-ready="onFlowchartReady"
                  />
                </b-col>
              </b-row>
            </div>
          </validation-observer>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end">
              <b-button variant="success" @click="save">
                <span v-if="!isUpdating">{{ $t("commands.btns.create") }}</span>
                <span v-else>{{ $t("commands.btns.update") }}</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="action_plan_preview_modal"
      ok-only
      hide-header
      no-close-on-esc
      size="md"
      centered
      scrollable
    >
      <action-plan-viewer
        v-if="isUpdating"
        :action-plan-id="idActionPlan"
        :preview="true"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormInvalidFeedback,
  BTabs,
  BTab,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import store from "@/store";
import useActionPlanConfig from "./useActionPlanConfig";
import FlowchartComponent from "./components/FlowchartComponent";
import ActionPlanViewer from "@/components/ActionPlanViewer";
import { computed, ref } from "@vue/composition-api";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    vSelect,
    Breadcrumb,
    ValidationProvider,
    ValidationObserver,
    FlowchartComponent,
    ActionPlanViewer,
  },
  setup(_, context) {
    // start breadcrumb
    const nameNav = ref("action_plan.title");
    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    // end breadcrumb

    return {
      ...useActionPlanConfig(),
      itemFilter,
      addItemFilter,
      nameNav,
    };
  },
};
</script>


