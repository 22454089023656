<template>
  <div>
    <p class="lead d-flex justify-content-between">
      <span>{{ $t("action_plan.viewer.execute_command") }}</span>
      <span class="small">{{ action.data.timestamp }}</span>
    </p>
    <div v-for="(cmd, idx) in action.node_data.filled_stack" :key="idx">
      <!-- Command -->
      <template v-if="cmd.type === 'cmd'">
        <feather-icon icon="PlayCircleIcon" size="18" class="mr-25" />
        {{ resolveNodeName(cmd) }}
        <div class="cmd-data pl-2 small" v-html="parseCmdData(cmd)"></div>
        <template>
          <b-button
            variant="primary"
            size="sm"
            @click="onExecuteCmdClick(action)"
            :disabled="terminated"
            v-if="!action.data.executed"
          >
            <feather-icon icon="PlayCircleIcon" size="18" class="mr-25" />
            {{ $t("action_plan.viewer.execute") }}
          </b-button>

          <!-- Command status ok -->
          <span
            class="text-success"
            v-if="action.data.executed && action.data.status === 'OK'"
          >
            <feather-icon icon="CheckCircleIcon" size="18" class="mr-25" />
            {{ $t("action_plan.viewer.completed") }}
          </span>

          <!-- Command status error -->
          <span
            class="text-danger"
            v-if="action.data.executed && action.data.status === 'ERROR'"
          >
            <feather-icon icon="XCircleIcon" size="18" class="mr-25" />
            {{ $t("action_plan.viewer.error") }}
          </span>
        </template>
        <div class="my-1">
          <label :for="`input-${action.id}`">
            {{ $t("action_plan.viewer.comments") }}
          </label>
          <input
            type="text"
            :id="`input-${action.id}`"
            v-model="action.data.comments"
            class="form-control"
            :placeholder="$t('action_plan.viewer.comments')"
            :disabled="terminated"
          />
        </div>
      </template>

      <!-- Delay -->
      <template v-else>
        <feather-icon icon="PauseCircleIcon" size="18" class="mr-25" />
        {{ resolveNodeName(cmd) }} ({{ cmd.data }}
        {{ $t("commands.units.seconds") }})
      </template>
    </div>
    <div class="text-right mb-1">
      <template v-if="!action.node_data.required">
        <b-button
          variant="primary"
          size="sm"
          @click="onContinueClick(action)"
          v-if="!action.data.continue && !terminated"
        >
          {{ $t("action_plan.viewer.continue") }}
          <feather-icon icon="ChevronRightIcon" size="18" />
        </b-button>
      </template>
      <template v-else>
        <b-button
          variant="primary"
          size="sm"
          @click="onContinueClick(action)"
          :disabled="!action.data.executed"
          v-if="!action.data.continue && !terminated"
        >
          {{ $t("action_plan.viewer.continue") }}
          <feather-icon icon="ChevronRightIcon" size="18" />
        </b-button>
      </template>
    </div>
  </div>
</template>

<script>
import commandStackHelperMixin from "@/mixins/commandStackHelperMixin";
import axios from "@axios";

export default {
  mixins: [commandStackHelperMixin],
  props: {
    action: Object,
    terminated: Boolean,
    preview: Boolean,
  },
  methods: {
    onContinueClick(action) {
      this.$emit("continue", action);
    },
    async onExecuteCmdClick(action) {
      if (!this.preview) {
        try {
          await axios.post("/v1/action_plan/run-command", { cmd: action.node_data });
          this.$set(action.data, "executed", true);
          this.$set(action.data, "status", "OK");
        } catch (error) {
          this.$set(action.data, "executed", true);
          this.$set(action.data, "status", "ERROR");
        }
      } else {
        this.$set(action.data, "executed", true);
        this.$set(action.data, "status", "OK");
      }
    },
    resolveNodeName(nodeData) {
      return nodeData.cmd !== "custom"
        ? this.$t(`commands.list.${nodeData.cmd_name}.name`)
        : nodeData.cmd_name;
    },
  },
};
</script>

<style></style>
