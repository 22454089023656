<template>
  <div>
    <p class="lead mb-1 d-flex justify-content-between">
      <span>{{ action.node_data.question }}</span>
      <span class="small">{{ action.data.timestamp }}</span>
    </p>
    <div v-if="!action.data.answer" class="d-flex justify-content-between mt-2">
      <div>
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          v-for="(answer, idx) in action.node_data.answer_options"
          :key="idx"
          :disabled="terminated"
          @click="$emit('answer-selected', { answer, action })"
        >
          {{ answer }}
        </b-button>
      </div>
    </div>
    <div v-else>{{ $t("action_plan.viewer.answer") }}: {{ action.data.answer }}</div>
    <div class="mt-1">
      <label :for="`input-${action.id}`"> {{ $t("action_plan.viewer.comments") }} </label>
      <input
        type="text"
        :id="`input-${action.id}`"
        v-model="action.data.comments"
        class="form-control"
        :placeholder="$t('action_plan.viewer.comments')"
        :disabled="terminated"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    action: Object,
    terminated: Boolean,
    preview: Boolean,
  },
};
</script>

<style></style>
