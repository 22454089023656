import { useToast } from "vue-toastification/composition";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import axios from "@axios";
import {
  computed,
  onMounted,
  ref,
  watch,
  reactive,
  getCurrentInstance,
  nextTick
} from "@vue/composition-api";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { image } from "vee-validate/dist/rules";
import useFlowchartValidations from "@/composables/flowchartValidations"

export default function useCustomCommandConfig() {

  const isUpdating = ref(false);
  const idActionPlan = ref(null);
  const actionPlanName = ref("");
  const idTenant = ref(null);
  const defaultActionPlan = ref(false);
  const selectedAlarmType = ref([]);

  const { refFormObserver, getValidationState } = formValidation();

  const toast = useToast();
  const { t } = useI18nUtils();
  const vm = getCurrentInstance().proxy;

  const MAX_NODE_CONNECTIONS = 3;
  const flowchartError = ref(null);
  const missingNodeData = ref([]);
  const invalidNodes = ref([]);

  const trans = {
    'commands.messages.success_created': t('commands.messages.success_created'),
    'commands.messages.success_updated': t('commands.messages.success_updated'),
  };

  // Computed Properties ------------------------------------
  const commandOptions = computed(() => store.getters['command/getSelectedItems']
    .map(c => ({
      value: c.value,
      label: c.label,
      cmd: c.cmd,
      requirements: c.requirements,
      custom: !!c.id_user
    })));
  const tenantOptions = computed(() => store.getters['tenant/getSelectedItems']);
  const alarmTypeOptions = computed(() => store.getters['alarm/getAlarmTypes']);

  // Methods ------------------------------------
  const onFlowchartReady = () => {
    if (router.currentRoute.params.id_action_plan) {
      isUpdating.value = true;
      idActionPlan.value = router.currentRoute.params.id_action_plan
      loadUpdateData(idActionPlan.value);
    }
  }

  const loadInitData = async () => {
    store.dispatch('command/getAll');
    store.dispatch('tenant/getAll');
    store.dispatch('alarm/getAlarmTypes');
  }

  const loadUpdateData = async (idActionPlan) => {
    const { data } = await store.dispatch("action_plan/show", idActionPlan);
    actionPlanName.value = data.name;
    idTenant.value = data.tenant.id;
    defaultActionPlan.value = data.default;
    selectedAlarmType.value = data.alarm_type;
    if (data.setup) {
      vm.$df.import(data.setup);
    }
  };

  const validateFlowchart = ({ drawflow }) => {

    const nodes = Object
      .values(drawflow.Home.data)
      .sort((a, z) => a.pos_x - z.pos_x);

    invalidNodes.value = [];
    missingNodeData.value = [];

    const { checkEmptyConnections, checkNodeValidity } = useFlowchartValidations(nodes, invalidNodes, missingNodeData);

    nodes.forEach(node => {
      checkEmptyConnections(node);
      checkNodeValidity(node);
    });

    flowchartError.value = invalidNodes.value.length ?
      invalidNodes.value[0] :
      null;

    return invalidNodes.value.length === 0 && missingNodeData.value.length === 0;
  };

  const save = async () => {
    const validation = await refFormObserver.value.validate();
    const flowChartData = vm.$df.export();

    if (!validateFlowchart(flowChartData)) return false;

    const data = {
      name: actionPlanName.value,
      default: defaultActionPlan.value,
      setup: flowChartData,
      alarm_type: selectedAlarmType.value,
      id_tenant: idTenant.value,
    }

    if (validation) {

      if (!isUpdating.value) {

        store.dispatch("action_plan/add", data).then(data => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['commands.messages.success_created'],
              icon: "CheckIcon",
              variant: "success",
            },
          });

          isUpdating.value = true;
          idActionPlan.value = data.data.id;

          router.push({ path: `/action-plan/config/${idActionPlan.value}` });
        });

      } else {

        store.dispatch("action_plan/edit", { id: idActionPlan.value, params: data }).then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: trans['commands.messages.success_updated'],
              icon: "CheckIcon",
              variant: "success",
            },
          });
        });

      }
    }

    return true;
  };

  const showActionPlanPreviewModal = async () => {
    const status = await save();
    if (!status) return;

    vm.$root.$emit('bv::show::modal', 'action_plan_preview_modal');
  };

  // Hooks ------------------------------------
  onMounted(async () => await loadInitData());

  return {
    isUpdating,
    actionPlanName,
    idTenant,
    idActionPlan,
    selectedAlarmType,
    alarmTypeOptions,
    defaultActionPlan,
    commandOptions,
    tenantOptions,
    refFormObserver,
    getValidationState,
    required,
    save,
    onFlowchartReady,
    flowchartError,
    missingNodeData,
    showActionPlanPreviewModal,
  };
}
