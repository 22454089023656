<template>
  <div
    ref="action_plan_preview_container"
    :class="[
      'action-plan-preview-container',
      { 'preview-mode': preview },
      { 'loading-action-plan': !canContinue },
    ]"
    v-if="actionPlan"
  >
    <template v-for="(action, index) in actions">
      <!-- ======= Decision ======= -->
      <decision
        v-if="action.type === 'Decision'"
        :class="[{ 'mb-2 border-bottom': actions.length > 1 }]"
        :key="index"
        :action="action"
        :terminated="
          isTerminated || action.data.action_plan_uuid != currentActionPlanUuid
        "
        :preview="preview"
        @answer-selected="onAnswerSelected"
      />

      <!-- ======= Command ======= -->
      <command
        v-else-if="action.type === 'Node'"
        class="mb-2 border-bottom"
        :key="index"
        :action="action"
        :terminated="
          isTerminated || action.data.action_plan_uuid != currentActionPlanUuid
        "
        :preview="preview"
        @continue="onContinueClick"
      />

      <!-- ======= Terminate ======= -->
      <div
        class="mb-2 border-bottom"
        v-else-if="action.type === 'Terminate'"
        :key="index"
      >
        <p class="lead">
          {{ $t("action_plan.viewer.finalized") }}
        </p>
      </div>

      <!-- ======= ActionPlan ======= -->
      <div
        class="mb-2 border-bottom"
        v-else-if="action.type === 'ActionPlan'"
        :key="index"
      >
        <p class="h2">
          {{ action.name }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import Decision from "./Decision.vue";
import Command from "./Command.vue";
import axios from "@axios";
import moment from "moment-timezone";
import { EventBus } from "@/libs/event-bus";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    Decision,
    Command,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    config: Object,
    actionPlan: {
      type: Object,
      default: [],
    },
    preview: Boolean,
    parentContainer: {
      type: HTMLDivElement,
      default: null,
    },
  },
  data() {
    return {
      currentActionPlanId: null,
      currentActionPlanUuid: null,
      actionPlanLogId: null,
      nodes: [],
      actions: [],
      container: null,
      terminated: false,
      actionPlanLocal: null,
      canContinue: true,
    };
  },
  created() {
    this.currentActionPlanId = this.actionPlan.id;
    this.actions = this.value.history || [];
    this.terminated = this.value.terminated;
    this.actionPlanLocal = this.actionPlan;
  },
  mounted() {
    this.container = this.parentContainer || this.$el;
    this.nodes = Object.keys(this.actionPlanLocal.nodes);

    if (!this.actions.length) {
      const root = this.actionPlanLocal.nodes[this.nodes[0]];
      root.actionPlanId = this.currentActionPlanId;

      const newActionPlan = {
        id: this.actionPlanId,
        type: "ActionPlan",
        name: this.actionPlanLocal.name,
        data: {
          timestamp: "",
          action_plan_id: "",
        },
      };

      this.addAction(newActionPlan, false);
      this.addAction(root);
    } else {
      const lastAction = this.getLatestAction();
      this.currentActionPlanUuid = lastAction.data.action_plan_uuid;
    }

    EventBus.on(`add_action_plan_${this.currentActionPlanId}`, this.onAddActionPlan);
  },
  beforeDestroy() {
    EventBus.off(`add_action_plan_${this.currentActionPlanId}`, this.onAddActionPlan);
  },
  computed: {
    isTerminated() {
      if (!this.actions.length && !this.actions.at(-1)) {
        return false;
      }

      return this.actions.at(-1)["type"] === "Terminate";
    },
  },
  watch: {
    actions(val) {
      this.$emit("input", {
        id: this.currentActionPlanId,
        terminated: this.isTerminated,
        history: val,
        config: this.config,
      });
    },
  },
  methods: {
    async addAction(action, logAction = true) {
      if (["Decision", "Node", "Terminate"].includes(action.type)) {
        const ts = moment.tz("America/Mexico_City").format("YYYY-MM-DD HH:mm:ss");
        this.$set(action.data, "timestamp", ts);
      }
      this.$set(action.data, "action_plan_id", this.currentActionPlanId);
      this.$set(action.data, "action_plan_uuid", this.currentActionPlanUuid);

      this.terminated = action.type === "Terminate";
      this.actions.push(action);
      if (logAction) await this.updateLog();
      this.scrollToElement();
    },
    getFirstAction() {
      return this.actions.at(0);
    },
    getLatestAction() {
      return this.actions.at(-1);
    },
    async onAnswerSelected(data) {
      const { answer, action } = data;
      this.$set(action.data, "answer", answer);
      const nextId = action.node_data.outputs.find((o) => o.text === answer).id;
      this.addAction(this.actionPlanLocal.nodes[nextId]);
    },
    async onContinueClick(action) {
      this.$set(action.data, "continue", true);
      const nextId = action.node_data.outputs.length
        ? action.node_data.outputs[0].id
        : null;
      if (nextId) this.addAction(this.actionPlanLocal.nodes[nextId]);
    },
    loadActionPlan(actionPlanId) {
      return axios
        .get(`/v1/action_plan/get-action-plan-json/${actionPlanId}`)
        .then(({ data }) => {
          const actionPlan = data.data;

          this.actionPlanLocal = actionPlan;
          this.currentActionPlanId = this.actionPlanLocal.id;
          this.currentActionPlanUuid = uuidv4();
          this.nodes = Object.keys(this.actionPlanLocal.nodes);

          const root = this.actionPlanLocal.nodes[this.nodes[0]];
          const newActionPlan = {
            id: this.currentActionPlanId,
            type: "ActionPlan",
            name: actionPlan.name,
            data: {
              action_plan_id: null,
              action_plan_uuid: null,
            },
          };

          this.addAction(newActionPlan);
          setTimeout(() => this.addAction(root), 500);
        });
    },
    onAddActionPlan(data) {
      this.loadActionPlan(data.actionPlanId);
    },
    async updateLog() {
      if (this.preview) return;

      this.canContinue = false;
      await axios.post("/v1/action_plan/update-action-plan-log", {
        id_action_plan: this.currentActionPlanId,
        uuid: this.config.uuid,
        id_alarm: this.config.alarmId,
        id_user: this.config.userId,
        log: this.value,
      });

      this.canContinue = true;
    },
    scrollToElement() {
      this.$nextTick(() => {
        this.container.scrollTop = this.container.scrollHeight;
      });
    },
  },
};
</script>

<style lang="scss">
.action-plan-preview-container {
  &.preview-mode {
    padding: 5px;
    max-height: 400px;
    overflow: auto;
    scroll-behavior: smooth;
  }
  &.loading-action-plan {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(209 209 209 / 43%);
    }
  }
}
</style>
