import { t } from "@/@core/libs/i18n/utils";

export default {
  methods: {
    parseCmdData(cmd) {
      let data = "";
      const dataTypes = cmd.data_types;
      const cmdData = cmd.data;

      dataTypes.forEach((dataType) => {
        if (cmdData[dataType]) {
          data += `<p class="mt-25 mb-25">${this.resolveParamName(dataType)}</p>`;
          data += "<ul class='mb-1 pl-2' style='max-width: 250px; max-height: 200px; overflow: auto;'>";
          if (Array.isArray(cmdData[dataType])) {
            cmdData[dataType].forEach((val) => (data += `<li>${this.resolveValue(dataType, val)} ${this.resolveSuffix(dataType)}</li>`));
          } else {
            data += `<li>${cmdData[dataType]}</li>`;
          }
          data += "</ul>";
        }
      });

      return data;
    },
    resolveValue(type, val) {
      switch (type) {
        case 'loop':
          return val === true ? this.$t('yes') : this.$t('no');

        case 'location':
          const coords = val.split(',');
          return `${coords[0]}, ${coords[1]}`;

        default:
          return val.name || val;
      }
    },
    resolveParamName(text) {
      return this.$t(`commands.data_types.${text}`);
    },
    resolveSuffix(type) {
      switch (type) {
        case 'delay':
          return this.$t('commands.units.seconds');
        case 'radius':
          return 'm';
        default:
          return '';
      }
    },
  },
}
