<template>
  <div class="flowchart">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end px-1">
          <div class="mb-1">
            <feather-icon
              icon="ZoomInIcon"
              size="25"
              class="cursor-pointer"
              @click="editor.zoom_in()"
            />
            <feather-icon
              icon="SearchIcon"
              size="25"
              class="mx-1 cursor-pointer"
              @click="editor.zoom_reset()"
            />
            <feather-icon
              icon="ZoomOutIcon"
              size="25"
              class="cursor-pointer"
              @click="editor.zoom_out()"
            />
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <!-- <b-form-input v-model="search" class="mb-3" :placeholder="$t('Search')" />
        <ul
          class="node-list list-unstyled px-1"
          style="max-height: 500px; overflow: auto"
        >
          <li
            v-for="(node, index) in filteredNodeList"
            :key="index"
            draggable="true"
            :data-node="node.item"
            class="drag-drawflow mb-1"
            @dragstart="drag($event)"
          >
            <div :class="['btn btn-block node', { custom: node.data.cmd === 'custom' }]">
              <span v-if="node.name === 'decision' || node.name === 'terminate'">
                {{ $t(`action_plan.nodes.${node.name}.name`) }}
              </span>
              <span v-else>{{ node.label }}</span>
            </div>
          </li>
        </ul> -->
        <b-form-input v-model="search" class="mb-3" :placeholder="$t('Search')" />
        <div style="max-height: 500px; overflow: auto">
          <command-list
            class="mb-3"
            :list="baseCommands"
            :search="search"
            :title="$t('commands.base_commands')"
            @node-drag-start="onNodeDragStart"
          />
          <command-list
            :list="customCommands"
            :search="search"
            :title="$t('commands.custom_commands')"
            @node-drag-start="onNodeDragStart"
          />
        </div>
      </div>
      <div class="col-md-10">
        <div
          id="drawflowActionPlan"
          @drop="drop($event)"
          @dragover="allowDrop($event)"
        ></div>
      </div>
    </div>
    <command-config-modal
      :current-node-requirements="currentNodeRequirements"
      v-model="currentNodeStorage"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Drawflow from "drawflow";
import Terminate from "./nodes/Terminate.vue";
import Decision from "./nodes/Decision.vue";
import Node from "@/components/flowchart/nodes/commands/Node.vue"; // TODO: Rename 'Node' to 'Command'
import NodeData from "@/components/flowchart/nodes/commands/NodeData";
import CommandConfigModal from "@/views/command/components/FlowchartComponent/CommandConfigModal.vue";
import { EventBus } from "@/libs/event-bus";
import CommandList from "@/views/command/components/FlowchartComponent/CommandList.vue";

export default {
  components: {
    NodeData,
    CommandConfigModal,
    CommandList,
  },
  props: {
    commandOptions: Array,
    flowchartData: Array,
    config: Object,
    missingNodeData: Array,
  },
  data() {
    return {
      editor: null,
      mobile_item_selec: "",
      mobile_last_move: null,
      nodeList: [],
      currentNodeId: null,
      currentNodeRequirements: null,
      currentNodeData: null,
      currentNodeStorage: null,
      showNodeData: false,
      search: "",
    };
  },
  async mounted() {
    const id = document.getElementById("drawflowActionPlan");
    Vue.prototype.$df = new Drawflow(id, Vue, this);
    this.editor = this.$df;
    this.editor.useuuid = true;
    this.editor.on("nodeSelected", this.onNodeSelected);
    this.editor.on("nodeUnselected", this.onNodeUnselected);
    this.editor.on("nodeRemoved", this.onNodeUnselected);
    // this.editor.on("connectionCreated", this.checkNodesIOs);
    // this.editor.on("connectionRemoved", this.checkNodesIOs);
    // this.editor.on("import", this.checkNodesIOs);

    this.editor.start();
    this.buildNodeList();
    this.initDraggableNodes();
    setTimeout(() => this.editor.zoom_out(), 200);

    this.editor.registerNode("Terminate", Terminate, {}, {});
    this.editor.registerNode("Decision", Decision, {}, {});
    this.editor.registerNode("Node", Node, { source: "action_plan" }, {});

    this.$emit("flowchart-ready");

    EventBus.on("node:open-modal", this.openConfigModal);
  },
  beforeDestroy() {
    EventBus.off("node:open-modal");
  },
  watch: {
    currentNodeStorage(val) {
      if (val) {
        this.currentNodeData.data.storage = val;
        this.editor.updateNodeDataFromId(this.currentNodeId, this.currentNodeData.data);
      }
    },
    missingNodeData(value) {
      if (value.length) {
        value.forEach((item) => {
          const node = document.querySelector(`#node-${item.id}`);
          node.classList.add("error");
        });
      } else {
        const nodes = document.querySelectorAll(".drawflow-node.Node");
        nodes.forEach((node) => node.classList.remove("error"));
      }
    },
  },
  computed: {
    baseCommands() {
      return this.nodeList.filter((node) => node.data && node.data.cmd !== "custom");
    },
    customCommands() {
      return this.nodeList.filter((node) => node.data && node.data.cmd === "custom");
    },
  },
  methods: {
    buildNodeList() {
      const actionTree = [
        {
          _id: 1,
          name: "decision",
          label: "Decision",
          item: "Decision_1",
          input: 1,
          output: 2,
          data: { config: null, storage: null },
        },
        {
          _id: 2,
          name: "terminate",
          label: "Terminate",
          item: "Terminate_2",
          input: 1,
          output: 0,
          data: { config: null, storage: null },
        },
      ];
      this.nodeList = this.nodeList.concat(actionTree).concat(
        this.commandOptions.map((cmd) => ({
          _id: cmd.value,
          name: cmd.label,
          label: !cmd.custom ? this.$t(`commands.list.${cmd.label}.name`) : cmd.label,
          item: `Node_${cmd.value}`,
          input: 1,
          output: 1,
          data: { ...cmd, required: false, storage: null },
          custom: cmd.custom,
        }))
      );
    },
    onNodeSelected(id) {
      this.currentNodeId = id;
      this.currentNodeData = this.editor.getNodeFromId(id);
      if (this.currentNodeData.data.requirements) {
        setTimeout(() => {
          this.currentNodeRequirements = this.currentNodeData.data.requirements;
          const nodeStorage = this.currentNodeData.data.storage;

          if (Array.isArray(nodeStorage) && nodeStorage.length === 0) {
            this.currentNodeStorage = null;
            return;
          } else if (JSON.stringify(nodeStorage) === "{}") {
            this.currentNodeStorage = null;
            return;
          } else if (!nodeStorage) {
            this.currentNodeStorage = null;
            return;
          }

          this.currentNodeStorage = this.currentNodeData.data.storage;
        }, 200);
      }
    },
    onNodeUnselected() {
      this.currentNodeId = null;
      this.currentNodeData = null;
      this.currentNodeRequirements = null;
      this.currentNodeStorage = null;
      // this.showNodeData = false;
    },
    openConfigModal(nodeId) {
      this.currentNodeId = nodeId;
      this.currentNodeData = this.editor.getNodeFromId(nodeId);
      if (this.currentNodeData.data.requirements) {
        setTimeout(() => {
          this.currentNodeRequirements = this.currentNodeData.data.requirements;
          this.currentNodeStorage = this.currentNodeData.data.storage;
          this.$root.$emit("bv::show::modal", "cmd_config_modal");
        }, 300);
      }
    },
    async checkNodesIOs() {
      await this.$nextTick();

      const nodes = Object.values(this.editor.export().drawflow.Home.data);
      nodes.forEach((node) => {
        const currentNode = document.getElementById(`node-${node.id}`);
        const outputs = currentNode.querySelector(".outputs");
        if (node.outputs.output_1.connections.length > 0) {
          outputs.classList.add("prevent-new-outputs");
        } else {
          outputs.classList.remove("prevent-new-outputs");
        }
      });
    },
    initDraggableNodes() {
      const elements = document.getElementsByClassName("drag-drawflow");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("touchend", this.drop, false);
        elements[i].addEventListener("touchmove", this.positionMobile, false);
        elements[i].addEventListener("touchstart", this.drag, false);
      }
    },
    onNodeDragStart(data) {
      this.mobile_item_selec = data;
    },
    drop(ev) {
      if (ev.type === "touchend") {
        var parentdrawflow = document
          .elementFromPoint(
            this.mobile_last_move.touches[0].clientX,
            this.mobile_last_move.touches[0].clientY
          )
          .closest("#drawflow");
        if (parentdrawflow != null) {
          this.addNodeToDrawFlow(
            this.mobile_item_selec,
            this.mobile_last_move.touches[0].clientX,
            this.mobile_last_move.touches[0].clientY
          );
        }
        this.mobile_item_selec = "";
      } else {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("node");
        this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);
      }
    },
    positionMobile(ev) {
      this.mobile_last_move = ev;
    },
    allowDrop(ev) {
      ev.preventDefault();
    },
    addNodeToDrawFlow(nodeName, pos_x, pos_y) {
      pos_x =
        pos_x *
          (this.editor.precanvas.clientWidth /
            (this.editor.precanvas.clientWidth * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().x *
          (this.editor.precanvas.clientWidth /
            (this.editor.precanvas.clientWidth * this.editor.zoom));
      pos_y =
        pos_y *
          (this.editor.precanvas.clientHeight /
            (this.editor.precanvas.clientHeight * this.editor.zoom)) -
        this.editor.precanvas.getBoundingClientRect().y *
          (this.editor.precanvas.clientHeight /
            (this.editor.precanvas.clientHeight * this.editor.zoom));

      const [name, id] = nodeName.split("_");
      const nodeSelected = this.nodeList.find((ele) => ele._id == id);

      this.editor.addNode(
        nodeSelected.data ? nodeSelected.data.label : nodeSelected.name,
        nodeSelected.input,
        nodeSelected.output,
        pos_x,
        pos_y,
        name,
        nodeSelected.data || {},
        name,
        "vue"
      );
    },
  },
};
</script>

<style lang="scss">
.node-data-config {
  margin-bottom: 10px;
  min-height: 65px;
  background-color: #fff;
  transition: all 0.4s;
  opacity: 0;
  &.show {
    opacity: 1;
  }
}
</style>
