<template>
  <div ref="el">
    <p>{{ $t('action_plan.nodes.terminate.name') }}</p>
  </div>
</template>

<script>
import nodeMixin from "@/mixins/nodeMixin";

export default {
  mixins: [nodeMixin],
};
</script>

<style></style>
