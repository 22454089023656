<template>
  <div ref="el">
    <div class="main-question">
      <contenteditable
        tag="p"
        :contenteditable="true"
        v-model="question"
        :noNL="true"
        @keypress="handleMainQuestion"
      />
    </div>
    <div class="node-custom-outputs">
      <div class="custom-output" v-for="(output, index) in outputs" :key="index">
        <feather-icon
          icon="XCircleIcon"
          size="18"
          class="remove-icon"
          @click="removeOutput(output.id)"
        />
        <contenteditable
          tag="p"
          :contenteditable="true"
          v-model="output.text"
          :noNL="true"
          @keypress="handleInput($event, index)"
        />
      </div>
    </div>
    <feather-icon icon="PlusCircleIcon" size="18" class="add-icon" @click="addOutput" />
  </div>
</template>

<script>
import { debounce } from "lodash";
import nodeMixin from "@/mixins/nodeMixin";
import contenteditable from "@/components/Contenteditable";

export default {
  mixins: [nodeMixin],
  components: {
    contenteditable,
  },
  data() {
    return {
      question: this.$t("action_plan.nodes.decision.config.question"),
      minOutputs: 2,
      outputs: [
        {
          id: "output_1",
          text: this.$t("action_plan.nodes.decision.config.yes"),
        },
        {
          id: "output_2",
          text: this.$t("action_plan.nodes.decision.config.no"),
        },
      ],
    };
  },
  async mounted() {
    await this.$nextTick();
    this.getNodeId();
    this.getNodeData();

    if (this.nodeData.data.config) {
      if (this.nodeData.data.config.question) {
        this.question = this.nodeData.data.config.question;
      }

      if (this.nodeData.data.config.custom_outputs) {
        this.outputs = this.nodeData.data.config.custom_outputs;
      }
    } else {
      this.nodeData.data.config = {};
      this.question = this.nodeData.data.config.question = this.$t(
        "action_plan.nodes.decision.config.question"
      );
      this.updateNodeConfig();
    }
  },
  computed: {
    outputsLength() {
      return this.outputs.length;
    },
  },
  methods: {
    addOutput() {
      if (this.outputsLength === 8) return;
      this.$df.addNodeOutput(this.nodeId);
      this.outputs.push({
        id: `output_${this.outputsLength + 1}`,
        text: this.$t("action_plan.nodes.decision.config.new_output"),
      });
      this.updateNodeConfig();
    },
    removeOutput(output) {
      if (this.outputsLength === 1) return;
      this.$df.removeNodeOutput(this.nodeId, output);
      this.outputs = this.outputs.filter((o) => o.id !== output);
      this.outputs.forEach((o, index) => {
        this.outputs[index].id = `output_${index + 1}`;
      });
      this.updateNodeConfig();
    },
    handleMainQuestion: debounce(function () {
      this.updateNodeConfig();
    }, 400),
    handleInput: debounce(function ($event, index) {
      if (this.outputs[index].text.length > 15) {
        $event.preventDefault();
        return;
      }
      this.updateNodeConfig();
    }, 400),
    updateNodeConfig() {
      this.nodeData.data.config.question = this.question;
      this.nodeData.data.config.custom_outputs = this.outputs;
      this.updateNodeData();
    },
  },
};
</script>

<style></style>
