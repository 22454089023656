<template>
  <div>
    <p class="h3 mb-0 text-center">
      {{ $t("action_plan.title") }}
      <span v-if="preview"> ({{ $t("action_plan.viewer.preview") }}) </span>
    </p>
    <hr />
    <div v-if="ready">
      <navigation-component
        v-model="history"
        :action-plan="actionPlan"
        :preview="preview"
        :parent-container="parentContainer"
        :config="config"
      />
    </div>
    <div class="pb-2 text-center" v-else>
      <b-spinner label="Spinning" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import axios from "@axios";
import NavigationComponent from "./NavigationComponent.vue";
export default {
  components: { NavigationComponent },
  props: {
    actionPlanId: {
      type: String | Number,
      required: true,
    },
    value: Object,
    config: Object,
    preview: {
      type: Boolean,
      default: false,
    },
    parentContainer: {
      type: HTMLDivElement,
      default: null,
    },
  },
  data() {
    return {
      ready: false,
      actionPlan: [],
      history: [],
    };
  },
  created() {
    this.history = this.value || {};
  },
  watch: {
    history(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    axios
      .get(`/v1/action_plan/get-action-plan-json/${this.actionPlanId}`)
      .then(({ data }) => {
        this.ready = true;
        this.actionPlan = data.data;
      });
  },
  methods: {
    onAnswerSelected(answere) {
      this.addToHistory(this.currentNode);
      this.currentNode = this.currentNode.answers[answere];
    },
    onPrevClicked() {
      if (this.history.length) {
        const node = this.history.pop();
        this.currentNode = node === "root" ? this.root : node;
      }
    },
    addToHistory(node) {
      if (node.root) {
        this.history.push("root");
        return;
      }

      if (!this.history.find((n) => node.id == n.id)) {
        this.history.push(node);
      }
    },
  },
};
</script>

<style></style>
